<template>
	<div class="page">
		<img class="bj" src="../../assets/image/blgbanner.png" />
		<div>
			<router-link tag="div" to="/" class="logo">
				<img src="./assets/logo.png" />
			</router-link>
			<div class="form" v-if="!success">
        <div style="color:#666;font-size:18px;margin-bottom:33px;line-height:18px;cursor: default;">—<span style="margin:0 10px;color:#333;">联系我们</span>—</div>
				<div class="form-item">
					<div
						class="form-input flex"
						:class="{ error: !formValidate.mobile.valid }"
					>
						<div slot="label">
							<img @click="$router.push('/')" src="./assets/mobile.png" />
						</div>
						<input
							v-model="form.mobile"
							placeholder="请输入手机号（必填）"
							maxlength="11"
						/>
					</div>
					<div class="form-error" v-if="!formValidate.mobile.valid">
						{{ formValidate.mobile.msg || "请输入手机号" }}
					</div>
					<div v-else style="width: 10px; height: 10px"></div>
				</div>
				<div class="form-item">
					<div class="form-input flex">
						<div slot="label">
							<img src="./assets/name.png" />
						</div>
						<input v-model="form.name" placeholder="请输入姓名" />
					</div>
					<div class="form-error" v-if="false">请输入姓名</div>
					<div v-else style="width: 10px; height: 10px"></div>
				</div>
				<div class="form-item">
					<div class="form-input flex">
						<div slot="label">
							<img src="./assets/company.png" />
						</div>
						<input
							v-model="form.company"
							placeholder="请输入企业名称"
						/>
					</div>
					<div class="form-error" v-if="false">请输入企业名称</div>
					<div v-else style="width: 10px; height: 10px"></div>
				</div>
				<div class="form-item">
					<div class="form-input flex">
						<div slot="label">
							<img src="./assets/email.png" />
						</div>
						<input v-model="form.email" placeholder="请输入邮箱" />
					</div>
					<div class="form-error" v-if="false">请输入邮箱</div>
					<div v-else style="width: 10px; height: 10px"></div>
				</div>
				<div class="form-item">
					<div class="form-input flex">
						<div slot="label">
							<img src="./assets/industry.png" />
						</div>
						<input
							v-model="form.industry"
							placeholder="请输入行业"
						/>
					</div>
					<div class="form-error" v-if="false">请输入行业</div>
				</div>
				<div class="subBtn" @click="submit">
					{{ loading ? "提交中" : "完成" }}
				</div>
				<router-link tag="span" to="/login" class="toLogin"
					>系统登录</router-link
				>
			</div>
			<div class="success" v-else>
				<img src="./assets/对钩@2x.png">
				<div class="title">资料已送出</div>
				<div class="info">
					如需立即咨询或服务，请拨打灵妙免费热线：
					<br>
					400-880-9378
				</div>
				<div class="subBtn" @click="$router.push('/')">继续浏览灵妙官网</div>
			</div>
			<div class="info">灵妙科技赋能企业数字化转型，⽤数据驱动发展</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	data() {
		return {
			form: {
				mobile: "",
				name: "",
				company: "",
				industry: "",
				email: "",
			},
			formValidate: {
				mobile: {
					valid: true,
					msg: "",
				},
			},
			loading: false,
			success: false
		};
	},
	methods: {
		submit() {
			if (!this.form.mobile) {
				this.formValidate.mobile = {
					valid: false,
					msg: "请输入手机号",
				};
        return
			} else {
				if (
					!/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
						this.form.mobile
					)
				) {
					this.formValidate.mobile = {
						valid: false,
						msg: "请输入正确的手机号",
					};
          return
				}
				this.formValidate.mobile = {
					valid: true,
					msg: "",
				}
			}
      this.loading = true;
      axios.post(`/website/api/website/email/send`,{
        ...this.form
      }).then((res) => {
        if(res.data.code == 200) {
					this.loading = false
					this.success = true
				}
      })
		},
	},
};
</script>

<style lang="less" scoped>
.page {
	height: 100vh;
	padding-top: 180px;
	box-sizing: border-box;
	.bj {
		width: 100vw;
		height: 100vh;
		object-fit: cover;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
	}
	.logo {
		img {
			width: 336px;
		}
	}
	.form {
		width: 380px;
		padding: 30px 40px;
		margin: 50px auto 0;
		border-radius: 4px;
		background: #ffffff;
		font-size: 18px;
		color: #333333;
		.form-item {
			.form-input {
				box-shadow: 0px 1px 0px 0px #d6e2ea;
				margin-bottom: 10px;
				&.error {
					box-shadow: 0px 1px 0px 0px #ec6e80;
					margin-bottom: 2px;
				}
				img {
					width: 20px;
					height: 44px;
					object-fit: scale-down;
					margin-left: 4px;
				}
				input {
					border: 0;
					font-size: 16px;
					width: 100%;
					margin-left: 35px;
					line-height: 44px;
					outline: none;
				}
			}
			.form-error {
				font-size: 12px;
				line-height: 30px;
				padding-left: 60px;
				text-align: left;
				color: #ec6e80;
			}
		}
		.subBtn {
			margin: 40px 0 20px;
			background: #3a9eff;
			border-radius: 4px;
			line-height: 46px;
			color: #ffffff;
			font-size: 16px;
			box-shadow: 0px 10px 30px 0px rgba(58, 158, 255, 0.3);
			cursor: pointer;
		}
		.toLogin {
			font-size: 16px;
			color: #3a9eff;
			line-height: 1;
			cursor: pointer;
		}
	}
	.success {
		width: 380px;
		padding: 30px 40px;
		margin: 50px auto 0;
		border-radius: 4px;
		background: #ffffff;
		font-size: 18px;
		color: #333333;
		img {
			width: 56px;
			margin: 30px 0;
		}
		.subBtn {
			margin: 40px 0 20px;
			background: #3a9eff;
			border-radius: 4px;
			line-height: 46px;
			color: #ffffff;
			font-size: 16px;
			box-shadow: 0px 10px 30px 0px rgba(58, 158, 255, 0.3);
			cursor: pointer;
		}
		.info {
			height: 40px;
			font-size: 14px;
			color: #666666;
			line-height: 28px;
		}
	}
	.info {
		padding: 50px 0 50px;
		width: 100%;
		height: 14px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffffff;
		line-height: 32px;
		opacity: 0.4;
		@media screen and (max-aspect-ratio: 2/1) {
			position: fixed;
			bottom: 60px;
		}
	}
}
</style>
